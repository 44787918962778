import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import MuxPlayer from "@mux/mux-player-react";
import VideoElements from "./VideoElements.tsx";
import { PageData } from "@/gem.types.js";
import { Gem } from "@/gem.types";
import { hc } from "hono/client";
import type { AppType } from "../../../packages/functions/src/gems/api/public";
import type { VideoProps } from "./Scenes.tsx";
import type { VideoElement } from "./VideoElements.tsx";

const Container = styled.div<{
  $fill: "yes" | "no" | null;
  $visible: "yes" | "no";
}>`
  position: relative;
  height: 100%;
  width: 100%;
  visibility: ${({ $visible }) => ($visible === "yes" ? "inherit" : "hidden")};
  ${({ $fill }) => {
    if ($fill === "yes") {
      return `video {
    object-fit: cover !important;
  }`;
    }
  }}
`;

type Props = {
  apis: Record<string, string>;
  gem: Gem;
  theme: PageData["theme"];
  isActive: boolean;
  playbackId: string;
  attribution?: string;
  gemEndpoint: string;
  calendarEndpoint: string;
  mediaEndpoint: string;
  elements: VideoElement[];
  contactUrl: {
    userId: string;
    contactId: string;
  };
  nextGem: VideoProps | null;
  onEnd: {
    behavior: string;
    uri: string;
  };
  onChangeScene?: (gemId: string) => void;
};

const Player = ({
  apis,
  gem,
  theme,
  isActive,
  playbackId,
  attribution,
  gemEndpoint,
  calendarEndpoint,
  mediaEndpoint,
  elements,
  onEnd,
  onChangeScene,
  contactUrl,
}: Props) => {
  const ref = useRef();
  const [elapsed, setElapsed] = useState(0);
  const [emergencyKey, setEmergencyKey] = useState<string>();
  const [mediaWidth, setMediaWidth] = useState(0);
  const [mediaHeight, setMediaHeight] = useState(0);
  const [shouldFill, setShouldFill] = useState<boolean | null>(null);
  const [haveDispatchedPlayEvent, setHaveDispatchedPlayEvent] = useState(false);

  useEffect(() => {
    if (isActive && elapsed > 1) {
      setEmergencyKey(Date.now().toString());
    }
  }, [isActive]);

  const calculateVideoDimensions = (player: any) => {
    const videoRatio = player.videoWidth / player.videoHeight;
    let width = player.offsetWidth;
    let height = player.offsetHeight;
    const elementRatio = width / height;
    if (elementRatio > videoRatio) width = height * videoRatio;
    else height = width / videoRatio;
    return {
      width,
      height,
    };
  };

  const onPlay = async () => {
    const client = hc<AppType>(gemEndpoint, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!contactUrl) return;
    try {
      await client.public.activity.$post({
        json: {
          userId: contactUrl.userId,
          gemId: gem.gemId,
          gemName: gem.name,
          activityType: "play",
          contactId: contactUrl.contactId,
        },
      });
    } catch (error) {
      console.error("Error logging activity:", error);
    }
  };

  const getVideoDimensions = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const player = ref.current;
      return calculateVideoDimensions(player);
    } catch (e) {
      return {
        width: null,
        height: null,
      };
    }
  };

  const setVideoSize = (width?: string, height?: string) => {
    if (!width && !height) {
      const dimensions = getVideoDimensions();
      width = dimensions.width;
      height = dimensions.height;
    }
    if (width && height) {
      const innerHeight = Number(window.innerHeight);
      const innerWidth = Number(window.innerWidth);
      let w = Number(width);
      let h = Number(height);
      const widthDifferential = innerWidth - w;
      const heightDifferential = innerHeight - h;

      if (height > width) {
        if (
          widthDifferential >= 0 &&
          widthDifferential < 40 &&
          heightDifferential < 30
        ) {
          setShouldFill(true);
          w = innerWidth;
          h = innerHeight;
        } else {
          setShouldFill(false);
        }
      }

      if (height < width) {
        if (
          heightDifferential === 0 &&
          widthDifferential > 0 &&
          widthDifferential < 200
        ) {
          setShouldFill(true);
          w = innerWidth;
          h = innerHeight;
        } else {
          setShouldFill(false);
        }
      }

      setMediaWidth(w);
      setMediaHeight(h);
    }
  };

  const handleVideoSize = () => {
    setVideoSize();
  };

  useEffect(() => {
    window.addEventListener("resize", handleVideoSize);
    const interval = setInterval(() => {
      const { width, height } = getVideoDimensions();
      if (width && height) {
        clearInterval(interval);
        setVideoSize();
      }
    }, 500);
    return () => {
      window.removeEventListener("resize", handleVideoSize);
    };
  }, []);

  const handleEnd = () => {
    if (onEnd.behavior === "autoplay") {
      const nextVideoGem = elements.find(
        (element) => element.type === "gem" && element.gem.type === "video",
      );
      if (nextVideoGem && onChangeScene && nextVideoGem.type === "gem") {
        onChangeScene(nextVideoGem.gem.gemId);
      }
    }
  };

  return (
    <>
      <Container
        $fill={shouldFill ? "yes" : "no"}
        $visible={isActive ? "yes" : "no"}
      >
        {isActive && mediaHeight && mediaWidth ? (
          <VideoElements
            apis={apis}
            gemEndpoint={gemEndpoint}
            contactUrl={contactUrl}
            elapsed={elapsed}
            gem={gem}
            theme={theme}
            elements={elements}
            attribution={attribution}
            calendarEndpoint={calendarEndpoint}
            mediaEndpoint={mediaEndpoint}
            videoHeight={mediaHeight}
            videoWidth={mediaWidth}
            onChangeScene={onChangeScene}
          />
        ) : null}
        <MuxPlayer
          key={playbackId + emergencyKey}
          ref={ref as any}
          playbackId={playbackId}
          className="player"
          onPlay={async () => {
            if (!contactUrl) return;
            if (haveDispatchedPlayEvent) return;
            await onPlay();
            setHaveDispatchedPlayEvent(true);
          }}
          playsInline
          poster=""
          autoPlay={isActive}
          paused={!isActive}
          playbackRate={1}
          preload="auto"
          onTimeUpdate={(event) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setElapsed(event?.target?.currentTime);
          }}
          onEnded={handleEnd}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Container>
    </>
  );
};

export default Player;
