import React, { useEffect } from "react";
import hexToRgba from "../utils/hexToRgba";
import attribute from "../utils/attribute";
import GemElement from "./GemElement";
import type { PageElement } from "../types";
import InnerHTML from "dangerously-set-html-content";
import { HTMLContent } from "./HTMLContent";
import type { Gem, PageData } from "@/gem.types";

interface MiniGem {
  gemId: string;
  userId: string;
  name: string;
  type: Gem["type"];
  url: string;
  image: string;
  ogImage: string;
  shortId: string;
  playbackId: string;
  optimized: string;
  uri: string;
  text: string;
}

interface GemElement {
  enabled: true;
  type: "gem";
  gemId: string;
  gemUserId: string;
  id: string;
  gem: MiniGem;
}

export type VideoElement = (PageElement | GemElement) & {
  start: number;
};

interface VideoElementsProps {
  apis: Record<string, string>;
  gem: MiniGem;
  theme: PageData["theme"];
  elements: VideoElement[];
  attribution?: string;
  calendarEndpoint: string;
  mediaEndpoint: string;
  gemEndpoint: string;
  videoHeight?: number;
  videoWidth?: number;
  elapsed?: number;
  contactUrl: {
    userId: string;
    contactId: string;
  };
  onChangeScene?: (gemId: string) => void;
}

const VideoElements = ({
  apis,
  gem,
  theme,
  elements,
  attribution,
  calendarEndpoint,
  mediaEndpoint,
  gemEndpoint,
  videoWidth,
  elapsed,
  contactUrl,
  onChangeScene,
}: VideoElementsProps) => {
  useEffect(() => {
    function applyTheme(theme: PageData["theme"]) {
      document.documentElement.style.setProperty(
        "--page-background-color",
        theme?.backgroundColor || "",
      );
      document.documentElement.style.setProperty(
        "--page-primary-color",
        theme?.primaryColor || "",
      );
      document.documentElement.style.setProperty(
        "--page-button-text-color",
        theme?.buttonTextColor || "",
      );
      document.documentElement.style.setProperty(
        "--page-field-border-color",
        theme?.fieldBorderColor || "",
      );
      document.documentElement.style.setProperty(
        "--page-text-color",
        theme?.textColor || "",
      );
    }

    if (theme) {
      applyTheme(theme);
    }
  }, [theme]);

  const onClick = async (element: any) => {
    window.location.href = attribute(element.destination, attribution || "");
  };

  const renderElement = (element: VideoElement) => {
    if (elapsed === 0) {
      return <></>;
    }

    if (element.start && elapsed && element.start > elapsed) {
      return <></>;
    }

    switch (element.type) {
      case "text":
        return (
          <div
            className="mb-6 px-6 py-4 w-full pointer-events-none"
            style={{
              zIndex: 1,
              backgroundColor: hexToRgba(theme?.backgroundColor || "", 0.8),
              borderRadius: 4,
            }}
          >
            {element.style === "heading" && (
              <h1
                className="mb-6 text-3xl font-bold text-center w-full leading-tight"
                style={{ color: theme?.textColor, zIndex: 1 }}
              >
                {element.content}
              </h1>
            )}
            {element.style === "paragraph" && (
              <p
                className="mb-8 text-xl text-center w-full leading-relaxed"
                style={{ color: theme?.textColor, zIndex: 1 }}
              >
                {element.content}
              </p>
            )}
            {element.style === "block" && (
              <div
                className="prose lg:prose-lg prose-h1:text-3xl prose-h1:text-[--page-text-color] text-[--page-text-color] prose-h1:mb-0"
                style={{ lineHeight: 1.4, textAlign: element.alignment }}
              >
                <div dangerouslySetInnerHTML={{ __html: element.content }} />
              </div>
            )}
          </div>
        );
      case "html":
        return (
          <div
            className="mb-6 px-6 py-4 w-full"
            style={{
              zIndex: 1,
              borderRadius: 4,
            }}
          >
            <InnerHTML html={element.content} />
          </div>
        );
      case "button":
        return (
          <button
            className="relative flex items-center justify-center w-full py-2 px-4 mb-6 bg-white  border-2 rounded-full transition-transform transform hover:scale-105"
            onClick={() => onClick(element)}
            style={{
              borderColor: theme?.primaryColor,
              backgroundColor: hexToRgba(theme?.backgroundColor || "", 0.8),
              zIndex: 1,
            }}
          >
            <span
              className="text-lg font-semibold"
              style={{ color: theme?.textColor }}
            >
              {element.text}
            </span>
          </button>
        );
      case "gem":
        return (
          <GemElement
            apis={apis}
            contactUrl={contactUrl}
            parentGemId={gem.gemId}
            parentUserId={gem.userId}
            parentGemName={gem.name}
            gemEndpoint={gemEndpoint}
            element={element}
            theme={theme}
            attribution={attribution}
            calendarEndpoint={calendarEndpoint}
            mediaEndpoint={mediaEndpoint}
            isVideo={true}
            onChangeScene={onChangeScene}
          />
        );
      case "html":
        return (
          <div
            className="mb-6 px-6 py-4 w-full"
            style={{
              zIndex: 1,
              backgroundColor: hexToRgba(theme?.backgroundColor || "", 0.8),
              borderRadius: 4,
            }}
          >
            <HTMLContent content={element.content} />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className="overflow-y-scroll py-12 flex flex-col items-center justify-end px-6 transition-all duration-300 ease-in-out"
      style={{
        height: "100%",
        width: videoWidth,
        position: "absolute",
        top: 0,
        bottom: "5%",
        left: 0,
        right: 0,
        margin: "auto",
        maxWidth: 600,
      }}
    >
      {elements.map((element, index) => (
        <React.Fragment key={index}>
          {renderElement(element) as React.ReactNode}
        </React.Fragment>
      ))}
    </div>
  );
};

export default VideoElements;
