import { useState, useEffect, useCallback } from "react";
import { motion, MotionStyle } from "framer-motion";
import Player from "./Player";
import { PageData } from "@/gem.types";
import { hc } from "hono/client";
import { AppType } from "../../../packages/functions/src/gems/api/public";

const containerStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  overflow: "hidden",
};

export type VideoProps = {
  gemId: string;
  userId: string;
  name: string;
  type: string;
  playbackId: string;
  elements: Array<any>;
  shortId: string;
};

type Props = {
  apis: Record<string, string>;
  theme: any;
  initialGem: VideoProps;
  calendarEndpoint: string;
  mediaEndpoint: string;
  gemEndpoint: string;
  contactUrl: {
    userId: string;
    contactId: string;
  };
  attribution?: string;
};

const Scenes = ({
  initialGem,
  calendarEndpoint,
  gemEndpoint,
  mediaEndpoint,
  contactUrl,
  attribution,
  theme,
  apis,
}: Props) => {
  const [playlist, setPlaylist] = useState<VideoProps[]>([initialGem]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [nextGems, setNextGems] = useState<VideoProps[]>([]);

  const fetchGem = useCallback(
    async (shortId: string) => {
      const client = hc<AppType>(gemEndpoint);
      try {
        const response = await client.public.gem[":id"].$get({
          param: { id: shortId },
        });
        const fetchedGem = await response.json();
        return fetchedGem.gem as VideoProps;
      } catch (error) {
        console.error("Error fetching gem:", error);
        return null;
      }
    },
    [gemEndpoint],
  );

  const prefetchNextGems = useCallback(async () => {
    const currentElements = playlist[activeIndex].elements;
    const nextVideoElements = currentElements.filter(
      (element) => element.type === "gem" && element.gem.type === "video",
    );

    const fetchedGems = await Promise.all(
      nextVideoElements.map((element) => fetchGem(element.gem.shortId)),
    );

    setNextGems(fetchedGems.filter((gem): gem is VideoProps => gem !== null));
  }, [playlist, activeIndex, fetchGem]);

  useEffect(() => {
    prefetchNextGems();
  }, [prefetchNextGems]);

  const handleChangeScene = async (shortId: string) => {
    const nextGem = nextGems.find((gem) => gem.shortId === shortId);
    if (nextGem) {
      setActiveIndex((prev) => prev + 1);
      setPlaylist((prev) => [...prev, nextGem]);
      setNextGems([]);
    } else {
      console.error("Next gem not found:", shortId);
    }
  };

  return (
    <>
      {[...playlist, ...nextGems].map((gem, index) => (
        <motion.div
          style={
            {
              ...containerStyle,
              visibility: index === activeIndex ? "visible" : "hidden",
            } as MotionStyle
          }
          key={gem.gemId}
        >
          {gem.playbackId && (
            <Player
              apis={apis}
              contactUrl={contactUrl}
              gem={gem}
              theme={theme as PageData["theme"]}
              elements={gem.elements}
              isActive={index === activeIndex}
              playbackId={gem.playbackId}
              attribution={attribution}
              gemEndpoint={gemEndpoint}
              calendarEndpoint={calendarEndpoint}
              mediaEndpoint={mediaEndpoint}
              onChangeScene={handleChangeScene}
              onEnd={{
                behavior: "pause",
                uri: "",
              }}
              nextGem={nextGems[0] || null}
            />
          )}
        </motion.div>
      ))}
    </>
  );
};

export default Scenes;
