import { useEffect, useRef, useState } from 'react';

export const HTMLContent = ({ content }: { content: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [dimensions, setDimensions] = useState({ width: '100%', height: '100px' });

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write(content);
        iframeDocument.close();

        const adjustDimensions = () => {
          if (iframeDocument.body) {
            const newHeight = `${iframeDocument.body.scrollHeight}px`;
            const newWidth = `${iframeDocument.body.scrollWidth}px`;
            setDimensions({ height: newHeight, width: newWidth });
          }
        };

        const resizeObserver = new ResizeObserver(adjustDimensions);

        iframe.onload = () => {
          if (iframeDocument.body) {
            resizeObserver.observe(iframeDocument.body);
            adjustDimensions();
            iframeDocument.addEventListener('click', (e) => {
              const target = e.target as HTMLAnchorElement;
              if (target.tagName === 'A') {
                e.preventDefault();
                window.open(target.href, '_blank');
              }
            });
          }
        };
        window.addEventListener('resize', adjustDimensions);

        return () => {
          if (iframeDocument.body) {
            resizeObserver.unobserve(iframeDocument.body);
          }
          resizeObserver.disconnect();
          window.removeEventListener('resize', adjustDimensions);
        };
      }
    }
  }, [content]);

  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <iframe
        ref={iframeRef}
        style={{
          border: 'none',
          width: dimensions.width,
          height: dimensions.height,
          overflow: 'hidden'
        }}
        title="Custom HTML Content"
      />
    </div>
  );
};
